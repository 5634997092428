.wysiwyg {
    p {
        margin-bottom: calc((22/16) * 1em);
    }

    ul {
        list-style: disc;
    }

    ol {
        list-style: decimal;
    }

    ul, ol {
        @apply my-[2.5em] pl-7% pr-10%;
    }

    li {
        display: list-item;
        padding-left: 0.5em;
        margin-bottom: 0.75em;
    }

    p, ul, ol, li {
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }

    h2 {
        @apply text-28 leading-1_2 mt-[1em] mb-[0.5em];
    }

    h3 {
        @apply text-24 leading-1_2 mt-[1em] mb-[0.25em];
    }

    h4 {
        @apply text-20 leading-1_2 mt-[1em] mb-[0.25em];
    }

    h2, h3, h4, h5, h6 {
        @apply font-medium;
        &:empty {
            display: none;
        }
    }

    b, strong {
        @apply font-medium;
    }

    i, em {
        @apply italic;
    }

    blockquote {
        @apply text-grey font-medium italic border-l-1 border-grey/25 pl-7% pr-10% my-[2.5em];
        &:before {
            content: '«';
        }
        &:after {
            content: '»';
        }
    }
}

