@layer components {

    /**
        Værsågod custom Tailwind components
        https://tailwindcss.com/docs/adding-custom-styles#adding-component-classes
     */

    //.btn {
    //    @apply bg-blue m:bg-red;
    //}

    .page-title {
        @apply text-14 mp:text-16 leading-1 font-medium text-blue uppercase;
    }

    .preamble {
        @apply text-18 mp:text-22 leading-1_4 mp:leading-1_8 font-light;
    }

    .h0 {
        @apply text-24 mp:text-38 leading-1_2 font-medium;
    }

    .h1 {
        @apply text-22 mp:text-28 leading-1_4 font-medium text-blue;
    }

    .h2 {
        @apply text-18 mp:text-22 leading-1_4 font-medium text-blue;
    }

    .h3 {
        @apply text-15 mp:text-18 leading-1_2 font-medium text-blue;
    }

    .h4 {
        @apply font-medium text-blue;
    }

    .medium {
        @apply text-15 mp:text-17 leading-1_6;
    }

    .small {
        @apply text-12 mp:text-14 leading-1_2;
    }

    .default-link, .wysiwyg a {
        @apply text-red underline underline-offset-[0.2em] decoration-1 transition-colors duration-350 ease-out hover:decoration-transparent;
    }

    .pagination-number {
        @apply flex items-center justify-center w-[40px] h-[52px] -ml-1 border-1 border-grey/25;
    }

}
